<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Octubre 2022                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-container fluid>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title class="blueGlobal">
          <span class="text-h5">Mapeo de columnas {{ ((component == 'portafolio') ? 'del portafolio' : 'a homologar')
          }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <v-data-table :items-per-page="100" :headers="tablaPrevisualizacion.headers" :items="tablaPrevisualizacion.body"
            hide-default-footer class="elevation-1 tabla-input" :height="alturaTabla" fixed-header>

            <template v-slot:item.columnaMapear="{ item }">
              <v-select v-model="item.columnaMapear" @change="refrescarColumnasMapear"
                :items="tablaPrevisualizacion.columnasHomologador" item-value="value">
                <template v-slot:selection="{ item }">
                  {{ obtenerNombre(item) }}
                </template>
                <template v-slot:item="{ item }">
                  {{ obtenerNombre(item) }}
                </template>
              </v-select>
            </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancelar">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="submitForm">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar top v-model="snackbar.snackbar" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn small text v-bind="attrs" @click="snackbar.snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>

export default {
  created() {
  },
  data() {
    return {
      alturasTabla: {
        portafolio: '60vh',
        homologar: '48vh',
      },
      alturaTabla: '',
      snackbar: {
        snackbar: false,
        timeout: 2000,
        color: '',
        text: ''
      },
      dialog: false,
      tablaPrevisualizacion: {
        columnasHomologador: [],
        headers: [
          { text: 'Columna a mapear', value: 'columnaMapear', sortable: false, width: '20%' },
          { text: 'Columna del archivo', value: 'columnaArchivo', sortable: false, width: '20%' },
          { text: 'Ejemplo primera fila', value: 'primeraFila', sortable: false },
          { text: 'Ejemplo segunda fila', value: 'segundaFila', sortable: false },
        ],
        body: []
      },
      component: null
    }
  },
  methods: {
    // obtener nombre opcion
    obtenerNombre(item) {
      return item.text.charAt(0).toUpperCase() + item.text.slice(1).toLowerCase()
    },
    // cancela la seleccion de items y se limpia el formulario
    cancelar: function () {
      this.dialog = false;
      this.limpiarFormulario();
    },
    // se limpia el formulario 
    limpiarFormulario: function () {
      this.tablaPrevisualizacion.columnasHomologador = [];
      this.tablaPrevisualizacion.body = [];
      this.tablaPrevisualizacion.component = null;
    },
    // se crea el objeto el cual sera devuelto al componente padre
    submitForm: function () {
      // se traen los headers y se crea una variable interna
      const headers = this.tablaPrevisualizacion.columnasHomologador;
      // se recorre todos los items del body (columnas del archivo cargado)
      const columnasFiltradas = this.tablaPrevisualizacion.body.map((item, index) => {
        // se valida si el indice del item esta entre los headers
        const banderaIncluido = headers.some(element => {
          return element.value == item.columnaMapear
        });

        if (banderaIncluido) {
          return {
            columnaArchivo: index,
            columnaMapear: item.columnaMapear
          }
        }
      }).filter(item => item != undefined);
      // se valida que se tenga seleccionadas todos las columnas minimas
      if (columnasFiltradas.length == headers.length) {
        this.cancelar();
        this.$emit("beforeSubmitForms", { columnasFiltradas, component: this.component })
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = 'red';
        this.snackbar.text = 'Seleccione todas las columnas a mapear';
      }
    },
    // funcion necesaria para deshabilitar la opcion cuando ya fue seleccionada
    validarDeshabilitarOption: function (value) {
      return this.tablaPrevisualizacion.body.some(opt => opt.columnaMapear == value)
    },
    // se obtienen los headers para implementarlos en portafolio las opciones retornando una promesa
    getHeadersPortafolio() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("msa-tools/api/v1/portafolio/headers", { headers: { 'Accept': 'application/json' } })
          .then((result) => {
            resolve(result.data)
          }).catch((error) => {
            reject(error)
          });
      })
    },
    // se obtienen los headers para implementarlos en homologar las opciones retornando una promesa
    getHeadersHomologar() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("msa-tools/homologar/headers", { headers: { 'Accept': 'application/json' } })
          .then((result) => {
            resolve(result.data)
          }).catch((error) => {
            reject(error)
          });
      })
    },
    // Se encarga de recibir las props del componente padre
    cargarDatosTabla: async function (props) {
      // se limpia el formulario
      this.limpiarFormulario();
      // se recupera la informacion de las propiedades
      this.component = props.component;
      this.dialog = true;
      // opciones para la homologacion del portafolio
      if (props.component == 'portafolio') {
        this.alturaTabla = this.alturasTabla.portafolio;
        try {
          // se recuperan los headers del portafolio
          this.tablaPrevisualizacion.columnasHomologador = await this.getHeadersPortafolio();
        } catch (error) {
          console.error(error)
          this.snackbar.snackbar = true;
          this.snackbar.color = 'red';
          this.snackbar.text = 'Error al cargar los headers de los portafolios';
        }
      } else if (props.component == 'homologar') {
        this.alturaTabla = this.alturasTabla.homologar;
        try {
          // se recuperan los headers del portafolio
          this.tablaPrevisualizacion.columnasHomologador = await this.getHeadersHomologar();
          this.tablaPrevisualizacion.columnasHomologador = this.tablaPrevisualizacion.columnasHomologador.map(item => {
            return {
              ...item,
              disabled: false
            }
          })
        } catch (error) {
          console.error(error)
          this.snackbar.snackbar = true;
          this.snackbar.color = 'red';
          this.snackbar.text = 'Error al cargar los headers de homologacion';
        }
      }

      // se inicia con la lectura del archivo 
      let reader = new FileReader();

      let tmpBody = [];

      reader.onload = function (e) {
        // se obtienen las filas
        let contenido = e.target.result.split('\n');
        // cantidad maxima de items para visualizar
        const filasPrevisualizacionCantidad = (contenido.length >= 3) ? 3 : contenido.length;
        // se recorren la cantidad de filas anteriormente calculadas
        for (let i = 0; i < filasPrevisualizacionCantidad; i++) {
          const element = contenido[i];
          const columnas = element.split(';')
          // se recorren las columnas 
          columnas.forEach((item, index) => {
            let tmpFila = {
              columnaArchivo: null,
              columnaMapear: null,
              primeraFila: null,
              segundaFila: null,
            };
            // primera columna se inserta los datos al array final 
            if (i == 0) {
              tmpFila.columnaArchivo = (item) ? item.trim() : '';
              tmpBody.push(tmpFila)
            }
            // primera fila de la previsualizacion  de datos
            if (i == 1 && index < tmpBody.length) {
              tmpBody[index].primeraFila = (item) ? item.trim() : '';
            }
            // segunda fila de la previsualizacion  de datos
            if (i == 2 && index < tmpBody.length) {
              tmpBody[index].segundaFila = (item) ? item.trim() : '';
            }

          })

        }

      }
      // manejador de errores
      reader.onerror = function () {
        this.snackbar.snackbar = true;
        this.snackbar.color = 'red';
        this.snackbar.text = 'Error al cargar el archivo';
      };
      // se inicia con la lectura archivo cargado
      reader.readAsText(props.file);
      // se le asigna a la tabla los datos procesados
      this.tablaPrevisualizacion.body = tmpBody;

    },
    refrescarColumnasMapear() {
      this.tablaPrevisualizacion.columnasHomologador = this.tablaPrevisualizacion.columnasHomologador.map(element => {
        return {
          value: element.value,
          text: element.text,
          disabled: this.validarDeshabilitarOption(element.value)
        }
      })
    }
  },
  watch: {},
  computed: {},
  mounted() { },
  props: [],
}
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped lang="scss">
::v-deep .tabla-input div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-input div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

</style>