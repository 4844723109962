<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Diego Andres Macias Arciniegas                                ###### -->
<!-- ###### @date: Octubre 2022                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-container>

    <v-row style="overflow-x: hidden;">
      <v-col>
        <v-row class="pb-2">
          <v-col>
            <v-text-field dense outlined prepend-icon="label" label="Nombre producto COHAN"
              v-model="formularioFiltro.nombre" ref="nombre" hide-details="true"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field dense outlined prepend-icon="science" label="Laboratorio" v-model="formularioFiltro.laboratorio"
              ref="laboratorio" hide-details="true"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field type="number" outlined dense prepend-icon="fingerprint" label="Stone"
              v-model="formularioFiltro.stone" ref="stone" hide-details="true"></v-text-field>
          </v-col>

        </v-row>
        <hr>

        <v-row>
          <v-col>

            <v-data-table id="tabla-manual" :loading="cargandoDatos" fixed-header :headers="headers" :items="desserts"
              :items-per-page="formularioFiltro.itemsPorPaginaDefault"
              @update:items-per-page="(i) => formularioFiltro.itemsPorPaginaDefault = i" :page="formularioFiltro.page"
              @update:page="(p) => formularioFiltro.page = p" class="elevation-1 tabla-manual" height="55vh"
              :footer-props="footerProps" :server-items-length="formularioFiltro.itemsTotales">

              <template v-slot:body="{ items }">

                <tbody>
                  <tr v-for="item in items" :key="item.stone + randomKey()">
                    <td class="text-lg-right"
                      style="max-width: 5vh !important; padding: 0px; text-align: left !important; margin-left: 5px !important;">
                      <p style="margin: 0 0 0 8px !important;">
                        {{ item.stone }}
                      </p>
                    </td>
                    <td style="max-width: 32vh !important; padding: 0px;">
                      <p style="margin: 0 0 0 8px !important;">
                        {{ `${item.observacionAlternativa.trim() == '1' ? '*' : ''} ${item.nombre}` }}
                      </p>
                    </td>
                    <td style="max-width: 10vh !important; padding: 0px; text-align: left !important;">
                      <p style="margin: 0 0 0 13px !important;">
                        {{ item.laboratorio }}
                      </p>
                    </td>
                    <td class="text-lg-right"
                      style="max-width: 5vh !important; padding: 0px; text-align: left !important;">
                      <p style="margin: 0 0 0 11px !important;">
                        Medellín: {{ item.existenciaMedellin }}<br />
                      </p>
                    </td>
                    <td style="max-width: 10vh !important; padding: 0px; text-align: left !important;">
                      <p style="margin: 0 0 0 13px !important;">
                        {{ imprimirPrecioProducto(item.detalleStone) }}
                      </p>
                    </td>
                    <td class="text-lg-right" style="max-width: 5vh !important; text-align: left !important;">
                      <v-checkbox v-if="item.detalleStone || incluirCotizacion == false"
                        style="margin-left: 16px !important;" v-model="item.portafolioSeleccionado"
                        hide-details="true"></v-checkbox>
                    </td>
                  </tr>
                    <tr>
                    <td colspan="6" style="text-align: right;">
                      <v-btn v-if="!cargandoDatos" style="margin-top: 0.5rem;" color="blue darken-1" text
                        @click="handlerPortafolioManual()">Seleccionar</v-btn>
                    </td>
                  </tr>
                </tbody>

              </template>

            </v-data-table>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <!-- notificaciones (alertas) para el usuario de las acciones realizadas -->
    <v-snackbar top v-model="snackbar.model" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.model = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>

// delay entre cada cambio de texto para los filtros
const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  created() {

  },
  data() {
    return {
      // lista de bodegas admitidas 
      bodegasAdmitidas: [
        { bodega: 'existenciaMedellin', codigo: 10103 },
      ],
      cargandoDatos: false,
      snackbar: {
        model: null,
        timeout: 2000,
        color: 'red',
        text: ''
      },
      formularioFiltro: {
        page: 1,
        totalPaginas: 1,
        itemsPorPagina: 20,
        itemsPorPaginaDefault: 20,
        itemsTotales: 0,
        nombre: '',
        laboratorio: '',
        stone: '',
      },
      headers: [
        { text: 'STONE', value: 'stone', sortable: false },
        { text: 'NOMBRE', value: 'nombre', sortable: false },
        { text: 'LABORATORIO', value: 'laboratorio', sortable: false },
        { text: 'EXISTENCIAS', value: 'existencias', sortable: false },
        { text: 'PRECIO', value: 'precio', sortable: false },
        { text: 'SELECCIONAR', value: 'id', sortable: false, width: '5%' },
      ],
      desserts: [],
      footerProps: {
        showFirstLastPage: true,
        "show-current-page": true,
        'items-per-page-text': 'Ítems por página',
        'items-per-page-options': [10, 20],
        'items-length': 100
      },
    }
  },
  methods: {
    randomKey(){
      return Math.floor(Math.random() * 1000000) + 1;
    },
    obtenerNombreItemExistencia(item) {
      return `${item.codBode} - ${item.nomBode} - ${item.uniDisp}`;
    },
    reiniciarFormulario(iniciar = false) {
      this.desserts = [];
      this.formularioFiltro.page = 1;
      this.formularioFiltro.totalPaginas = 1;
      this.formularioFiltro.nombre = '';
      this.formularioFiltro.laboratorio = '';
      this.formularioFiltro.stone = '';
      if (iniciar) this.getPortafolio();
    },
    async getPortafolio(page = null) {
      this.desserts = [];
      if (page) this.reiniciarFormulario();
      this.cargandoDatos = true;
      await this.$http.get(`msa-tools/api/v1/portafolio`, {
        params: {
          page: this.formularioFiltro.page - 1,
          size: this.formularioFiltro.itemsPorPaginaDefault,
          nombre: `${this.formularioFiltro.nombre.toUpperCase()}`,
          laboratorio: `${this.formularioFiltro.laboratorio.toUpperCase()}`,
          stone: `${this.formularioFiltro.stone}`,
          tipoPortafolio: this.tipoPortafolioId,
          incluirCotizacion: this.incluirCotizacion,
          listaPrecio: this.listaPrecioCodigo
        }
      }).then(response => {
        this.formularioFiltro.itemsTotales = response.data.totalElements
        // se recorre el response 
        this.desserts = response.data.content.map(element => {
          let existenciasLista = {};
          // se añaden los atributos de las bodegas
          this.bodegasAdmitidas.forEach(item => existenciasLista[item.bodega] = 0)
          // se recorre las existencias del response
          if (element.existencias) {
            element.existencias.forEach(element => {
              // Se busca si la bodega del response existe en el listado local de bodegas 
              const bandera = this.bodegasAdmitidas.find(item => item.codigo == element.codBode)
              // si se encuentra se la añade a la lista
              if (bandera) {
                existenciasLista[bandera.bodega] = element.uniDisp
              }
            });
          }
          return {
            ...element,
            portafolioSeleccionado: false,
            // se añade al listado final
            ...existenciasLista
          }
        })

        this.formularioFiltro.totalPaginas = response.data.totalPages;
        this.formularioFiltro.itemsPorPagina = this.formularioFiltro.itemsPorPagina * this.formularioFiltro.page;
        this.cargandoDatos = false;
      }).catch(error => {
        this.snackbar.color = 'red';
        this.snackbar.text = 'Error al cargar el portafolio';
        // se consulta si el backend responde algun error 
        if (error?.response?.data) {
          this.snackbar.text = error?.response?.data?.mensaje;
        }
        this.cargandoDatos = false;
      })
    },
    handlerPortafolioManual() {
      const portafolios = this.desserts.filter(item => item.portafolioSeleccionado);
      this.reiniciarFormulario();
      this.$emit("handlerPortafolioManual", portafolios)
    },
    paginadoInicial() {
      this.desserts = [];
      this.formularioFiltro.page = 1;
      this.formularioFiltro.totalPaginas = 1;
    },
    imprimirPrecioProducto: function (item) {
      if (item) {
        // calculo de los impuestos
        const porcentajeImpuesto = item.porImpu;
        const impuesto = (item.valPrec * porcentajeImpuesto) / 100;
        const precioRedondeado = Math.ceil(item.valPrec + impuesto);
        return `$ ${precioRedondeado}`;
      }
      return "Producto no relacionado con la lista";
    }
  },
  watch: {
    'formularioFiltro.page': function () {
      this.getPortafolio();
    },
    'formularioFiltro.itemsPorPaginaDefault': function () {
      this.getPortafolio();
    },
    'formularioFiltro.laboratorio': debounce(function () {
      this.paginadoInicial();
      this.getPortafolio();
    }, 500),
    'formularioFiltro.nombre': debounce(function () {
      this.paginadoInicial();
      this.getPortafolio();
    }, 500),
    'formularioFiltro.stone': debounce(function () {
      this.paginadoInicial();
      this.getPortafolio();
    }, 500)
  },
  computed: {
    pageCount() {
      return this.formularioFiltro.totalPaginas
    },
  },
  mounted() {
    this.reiniciarFormulario();
  },
  props: {
    tipoPortafolioId: Number,
    incluirCotizacion: Boolean,
    listaPrecioCodigo: Number
  },
}
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style lang="scss" scoped>
.paginador-manual ul li .v-pagination__item {
  margin: 1px !important;
  min-width: 25px !important;
}

.paginador-manual {
  padding-top: 0%;
}

::v-deep .tabla-manual div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-manual div table thead tr th span {
  font-weight: bold;
  color: black !important;
}



/* Multiple seleccion */
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
  padding: 0px;
}

::v-deep .v-list-item {
  min-height: 0px !important;
}

::v-deep .v-data-footer {
  justify-content: flex-end;
}

::v-deep .v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  width: 100% !important;
}

::v-deep .v-list-item.v-list-item--link.theme--light {
  padding: 0px !important;
}

/* Multiple seleccion */

::v-deep .seleccionar-multiple .v-select__selection--comma {
  max-width: 20vh !important;
}

::v-deep #tabla-manual>div>table>thead>tr>th {
  padding: 0 0 0 -1px !important;
}

/* ::v-deep #tabla-manual > .v-data-footer:after {
  background-color: red !important;
  content: "<v-btn style=\"margin-top: 0.5rem;\" color=\"blue darken-1\" text @click=\"handlerPortafolioManual()\">Seleccionar</v-btn>";
} */
</style>