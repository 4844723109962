<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Oscar Andrés Ochoa Villegas                                   ###### -->
<!-- ###### @date: Enero 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div>
    <!-- content -->
    <v-container fluid v-if="!foco" style="height: 81vh; overflow-y: scroll;">

      <section class="texto-cabecera" style="display: flex;  align-items: center;">
        <v-icon class="ma-1 icono-tools" color="success" x-large>compare</v-icon>

        <div class="mx-3">Herramienta para homologar productos externos y portafolio de
          COHAN.
          Para su uso asegúrese de subir un archivo CSV con los campos: Cums, Nombre de Producto, Nombre
          Genérico,
          Laboratorio y Código del cliente.
          <p v-for="(item) in portafolioVersion" :key="`${item.version}${item.tipoPortafolio}`" class="pb-0 mb-0">
            Fecha del portafolio {{ item.tipoPortafolio }}: <strong>{{ item.fechaVersion }}</strong>
            versión:&#160;<strong>{{ item.version }}</strong>.

            <button style="color: #43A047 !important; text-decoration: underline;"
              @click="descargarPortafolio(item.tipoPortafolioId)">
              <strong>DESCARGAR</strong>
            </button>
          </p>

        </div>
      </section>
      <hr>

      <h2 class="pt-1 pb-1">Opciones para homologar</h2>
      <div style="display: flex; margin-bottom: auto; flex-wrap: wrap;">

        <v-text-field style="padding-right: 5px; max-width: 30%;  min-width: 30%;" dense outlined
          v-model="formularioHomologador.nombre" label="Nombre cotización" hide-details="true"></v-text-field>

        <v-select style="padding-right: 5px; max-width: 35%; min-width: 35%;" :item-value="'id'"
          v-model="formularioHomologador.tipoPortafolio" dense :item-text="'tipo'" outlined label="Tipo portafolio"
          :items="tipoPortafolios" hide-details="true"></v-select>

        <v-select style="padding-right: 5px; max-width: 35%;" v-model="formularioHomologador.incluirCotizacion" dense
          outlined hide-details="true" :label="`Incluir\u00A0cotización`" :items="listaSiNo"></v-select>

      </div>

      <div style="display: flex; margin-bottom: auto; flex-wrap: wrap; margin-top: 8px;"
        v-if="formularioHomologador.incluirCotizacion">

        <v-combobox :return-object="false" @change="autocompletarCliente" @keyup="buscarNit($event.target.value)"
          v-model="formularioHomologador.nit" label="Nit" type="number" min="1"
          style="padding-right: 5px; max-width: 25%; min-width: 25%;" dense outlined :items="clientes" item-text="numIden"
          item-value="numIden" hide-details="true"></v-combobox>

        <v-autocomplete style="padding-right: 5px; max-width: 25%; min-width: 25%;" return-object
          v-model="formularioHomologador.listaPrecio" dense :item-text="nombreListaCotizacion" outlined
          label="Lista de precios" :items="sucursalesClientes" hide-details="true"></v-autocomplete>

        <v-autocomplete :return-object="false" v-model="formularioHomologador.nombreEjecutivo"
          label="Nombre KAM/Ejecutivo" style="padding-right: 5px; max-width: 25%; min-width: 25%;" dense outlined
          :items="kamEjecutivo" @keyup="buscarEjecutivo($event.target.value)" item-text="nombreUsuario"
          item-value="usuario" hide-details="true"></v-autocomplete>

        <v-text-field style="padding-right: 5px; max-width: 25%;  min-width: 25%;" dense outlined
          v-model="formularioHomologador.nombreCliente" label="Nombre del cliente" hide-details="true"></v-text-field>

      </div>

      <div style="display: flex; flex-wrap: wrap; margin-bottom: auto; margin-top: 8px;"
        v-if="formularioHomologador.incluirCotizacion">


        <v-text-field style="padding-right: 5px; max-width: 23%;  min-width: 23%;" dense outlined
          v-model="formularioHomologador.ciudadCliente" label="Ciudad del cliente" hide-details="true"></v-text-field>

        <v-text-field style="padding-right: 5px; max-width: 21%;  min-width: 21%;" dense outlined
          v-model="formularioHomologador.direccionCliente" label="Dirección del cliente"
          hide-details="true"></v-text-field>

        <v-text-field style="padding-right: 5px; max-width: 23%;  min-width: 23%;" dense outlined
          v-model="formularioHomologador.telefonoCliente" label="Teléfono del cliente" hide-details="true"></v-text-field>

        <v-text-field style="padding-right: 5px; max-width: 18%;  min-width: 13%;" dense outlined
          v-model="formularioHomologador.porcentajeDescuento" type="number" label="Porcentaje de descuento"
          hide-details="true"></v-text-field>

        <v-text-field style="padding-right: 5px; max-width: 15%;  min-width: 13%;" dense outlined
          v-model="formularioHomologador.diasVigencia" type="number" min="0" label="Días vigencias"
          hide-details="true"></v-text-field>

      </div>

      <div style="display: flex; flex-wrap: wrap; margin-bottom: auto; margin-top: 8px;"
        v-if="formularioHomologador.incluirCotizacion">
        <v-textarea style="padding-right: 5px; max-width: 100%;  min-width: 100%;" dense outlined
          v-model="formularioHomologador.observacion" label="Observación" hide-details="true" rows="2"></v-textarea>
      </div>

      <!-- OPCIONES HOMOLOGACION -->
      <div style="display: flex; justify-content: space-between; margin-right: 300px; margin-bottom: auto;">

        <v-checkbox v-model="formularioHomologador.generico" :label="`Usar\u00A0nombres\u00A0genéricos`" hide-details>
        </v-checkbox>

        <v-checkbox v-model="formularioHomologador.comercial" :label="`Usar\u00A0nombres\u00A0comerciales`" hide-details>
        </v-checkbox>

        <v-checkbox v-model="formularioHomologador.cums" :label="`Usar\u00A0cums`" hide-details></v-checkbox>

        <v-checkbox v-model="formularioHomologador.laboratorio" :label="`Usar\u00A0nombres\u00A0de\u00A0laboratorios`"
          hide-details>
        </v-checkbox>

      </div>

      <!-- ICONO CARGANDO  -->
      <div class="text-center mt-4" v-if="formularioPortafolio.status == 1">
        <v-progress-circular :size="50" color="primary" indeterminate>
          <p class="texto-negro">Cargando...</p>
        </v-progress-circular>
      </div>

      <!-- CARGAR ARCHIVOS  -->
      <section style="display: flex; justify-content: space-between;" class="mt-2">

        <template v-if="formularioPortafolio.status != 1">
          <div class="dropbox desactivar-input" style="width: 99%;">
            <input type="file" @change="changeFilePopup('homologar')" ref="fieldFile" class="input-file" accept=".csv">

            <p v-if="isInitial">Arrastre el archivo CSV con los productos a homologar<br>También puede
              hacer
              click
              en este cuadro</p>
            <p v-if="isSaving">Homologando archivo... Tiempo transcurrido {{ time }} segundos</p>
          </div>

        </template>

      </section>
      <!-- CAMBIO DE PORTAFOLIO -->
      <section class="mt-2" v-if="userRoles.includes('add_portafolio_homologar')">
        <hr>
        <template v-if="formularioPortafolio.status != 1">
          <h2 class="pb-2">Actualizar portafolio</h2>
          <v-select class="pb-3" style="max-width: 35%;" :item-value="'id'" :item-text="'tipo'"
            v-model="formularioHomologador.tipoPortafolio" dense outlined label="Tipo portafolio" :items="tipoPortafolios"
            hide-details></v-select>

          <!--Portafolio -->
          <div class="dropbox " style="width: 99%;">
            <input type="file" :disabled="formularioPortafolio.status != 0" @change="changeFilePopup('portafolio')"
              ref="filePortafolio" class="input-file" accept=".csv">
            <p v-if="isInitial">Arrastre el archivo CSV con los productos del portafolio<br>También puede
              hacer
              click
              en este cuadro</p>
            <p v-if="isSaving">Homologando archivo... Tiempo transcurrido {{ time }} segundos</p>
          </div>

        </template>

      </section>

    </v-container>

    <!-- notificaciones (alertas) para el usuario de las acciones realizadas -->
    <v-snackbar top v-model="snackbar" :timeout="timeout" :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- modal cargar archivo homologacion y portafolio -->
    <v-row style="">
      <v-col>
        <keep-alive>
          <component :is="formularioSubirArchivo.component" ref="homologarInputFile"
            @beforeSubmitForms="beforeSubmitForms"></component>
        </keep-alive>
      </v-col>
    </v-row>

  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from 'vuex'
import HomologarManual from "@/components/HomologarManual.vue";
import HomologarInputFile from "@/components/HomologarInputFile.vue";

import { Role } from "@/router/role.js";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  data() {
    // SE CREA EL FORMULARIO PARA LA HOMOLOGACION CON LOS CAMPOS REQUERIDOS
    const formularioHomologador = {
      errors: [],
      generico: false,
      comercial: false,
      laboratorio: false,
      cums: false,
      nit: '',
      nombre: '',
      tipoPortafolio: 0,
      nombreCliente: '',
      direccionCliente: '',
      ciudadCliente: '',
      telefonoCliente: '',
      nombreEjecutivo: '',
      incluirCotizacion: true,
      porcentajeDescuento: 0,
      diasVigencia: 30,
      listaPrecio: '',
      observacion: '',
    };

    const formularioPortafolio = {
      msg: [],
      status: false,
      file: null
    }

    return {
      esperaConsulta: true,
      sucursalesClientes: [],
      kamEjecutivo: [],
      listaSiNo: [{ text: 'Si', value: true }, { text: 'No', value: false }],
      clientes: [],
      tipoPortafolios: [{
        id: 0,
        tipo: 'Servicio farmacéutico'
      }, {
        id: 1,
        tipo: 'Distribución'
      }],
      ocultarTabla: true,
      verMenu: true,
      roles: Role,
      userRoles: {},
      // formulario necesario para la homologacion de las columnmas a las tablas cohan
      formularioSubirArchivo: {
        file: null,
        component: 'HomologarInputFile',
      },
      //cambio de componente
      componenteActual: 'Dummy',
      itemPortafolioId: null,
      // dialogo 
      dialog: false,
      // filtro tabla homologacion
      filtroHomologacion: '',
      //alerta snackbar
      snackbar: false,
      text: '',
      timeout: 3000,
      color: 'blue',
      // formulario homologacion
      formularioHomologador,
      // formulario portaforlio
      formularioPortafolio,
      //versionado del portafolio
      portafolioVersion: [],
      tab: null,
      items: [
        'Homologador'
      ],
      csvHeadersInicio: ['Nombre genérico cliente', 'Nombre comercial cliente', 'Nombre laboratorio cliente'],
      csvHeadersFin: ['Stone', 'Nombre', 'Buffer'],
      exportAvailable: false,
      uploadError: null,
      currentStatus: null,
      formData: null,
      headers: [{ text: '', value: 'id', sortable: false, width: '1%' },
      //{ text: 'NOMBRE GENERICO', value: 'nombreGenerico', sortable: false }, { text: 'NOMBRE COMERCIAL', value: 'nombreComercial', sortable: false }, { text: 'LABORATORIO', value: 'laboratorio', sortable: false }, { text: 'CUMS', value: 'cums', sortable: false }, 
      { text: 'PRODUCTOS COHAN', value: 'portafolio', sortable: false }, { text: 'PORTAFOLIO', value: 'id', sortable: false }],
      itemsPerPageOptions: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
      productosHomologados: [],
      totalHomologado: 0,
      totalProductosCliente: 0,
      totalFaltante: 0,
      efectividad: 0,
      homologacionReady: false,
      foco: false,
      time: 0,
      totalTime: 0,
      interval: null,
      offsetHeigth: 500,
      contadorJ: 0
    }
  },
  created() {
    this.userRoles = this.auth.roles;
    this.getVersionPortafolio();
  },
  computed: {
    ...mapState(['auth', 'notify', 'busy']),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    ...mapMutations(['hideNotify', 'showSuccess', 'showBusy', 'hideBusy']),
    buscarEjecutivo: debounce(function (nombreUsuario) {
      this.getKamEjecutivo(nombreUsuario);
    }, 500),
    autocompletarCliente() {
      const cliente = this.clientes.find(element => element.numIden == this.formularioHomologador.nit);
      this.formularioHomologador.nombreCliente = ''
      this.formularioHomologador.ciudadCliente = ''
      this.formularioHomologador.direccionCliente = ''
      this.formularioHomologador.telefonoCliente = ''
      if (cliente) {
        this.formularioHomologador.nombreCliente = cliente.nomComp
        this.formularioHomologador.ciudadCliente = cliente.nomMpio
        this.formularioHomologador.direccionCliente = cliente.dirClie
        this.formularioHomologador.telefonoCliente = cliente.telClie
      }
      this.getSucursalesCliente();
    },
    getClientes() {
      this.clientes = [];
      this.$http
        .get(`msa-tools/api/v1/io-stone/clientes`, {
          params: {
            nit: this.formularioHomologador.nit
          }
        })
        .then(response => {
          this.clientes = response.data
          this.autocompletarCliente();
        })
        .catch(error => {
          this.esperaConsulta = true;
          console.error(error)
          this.snackbar = true;
          this.text = 'Error al cargar los clientes';
          this.color = 'error'
        })
    },
    getKamEjecutivo(nombreUsuario = "") {
      this.kamEjecutivo = [];
      this.$http
        .get(`msa-tools/api/v1/io-stone/kam-ejecutivo`, {
          params: {
            nombreUsuario: nombreUsuario
          }
        })
        .then(response => {
          this.kamEjecutivo = response.data;
        })
        .catch(error => {
          console.error(error)
          this.snackbar = true;
          this.text = 'Error al cargar KAM/Ejecutivos';
          this.color = 'error'
        })
    },
    getSucursalesCliente() {
      this.sucursalesClientes = [];
      this.$http
        .get(`msa-tools/api/v1/io-stone/sucursales-cliente`, {
          params: {
            nit: this.formularioHomologador.nit
          }
        })
        .then(response => {
          this.sucursalesClientes = response.data;
          if (this.sucursalesClientes && this.sucursalesClientes.length > 0) {
            this.formularioHomologador.listaPrecio = this.sucursalesClientes[0]
          }
        })
        .catch(error => {
          console.error(error)
          this.snackbar = true;
          this.text = 'Error al cargar las sucursales';
          this.color = 'error'
        })
    },
    descargarPortafolio(tipoPortafolio) {
      this.formularioPortafolio.status = 1;
      this.$http
        .get(`msa-tools/api/v1/portafolio/file/${tipoPortafolio}`, {
          responseType: 'blob',
        })
        .then((result) => {
          this.formularioPortafolio.status = 0;
          const href = URL.createObjectURL(result.data);

          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'portafolio.csv');
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);

        })
        .catch((error) => {
          this.formularioPortafolio.status = 0;
          console.error(error);
        });
    },
    //funcionar encargada de cambiar el estado "modificado" de cada itemCotizacion
    handlerChangeModificado: function (id) {
      this.productosHomologados = this.productosHomologados.map(item => {
        if (item.id == id) {
          item.modificado = true;
        }
        return item;
      })
    },
    //se obtiene el portafolio enviado desde el hijo
    handlerPortafolioManual: function (portafolio) {
      this.dialog = false;
      this.componenteActual = 'Dummy';

      this.productosHomologados = this.productosHomologados.map((item) => {
        // nos ubicamos en el item seleccionado 
        if (item.id == this.itemPortafolioId) {
          // buscamos si existe un item con el id igual en los productos cohan del item seleccionado 
          const banderaPortafolio = item.portafolio.some(element => element.id == portafolio[0].id);
          // se valida que no exista un item igual en los productos cohan
          if (!banderaPortafolio) {
            const tmpPortafolio = { distancia: 1000, ...portafolio[0] };
            item.vModel.push(tmpPortafolio)
            item.portafolio.push(tmpPortafolio);
            item.modificado = true;
          }
        }
        return item;
      })
    },
    handlerHomologarManual(id) {
      this.itemPortafolioId = id;
      this.dialog = true;
      this.componenteActual = 'HomologarManual';
    },
    resetUploadFile() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.formularioPortafolio.status = STATUS_INITIAL;
    },
    //se obtiene el versionado del portafolio
    getVersionPortafolio() {
      this.$http.get('msa-tools/api/v1/portafolio/max-version').then(response => {
        this.portafolioVersion = response.data
      }).catch(error => {
        console.error(error)
        this.snackbar = true;
        this.text = 'Error al cargar la version del portafolio';
        this.color = 'error'
      })
    },
    changeFilePopup: function (component) {

      if (component == 'portafolio') {
        if (this.$refs.filePortafolio.files.length > 0) {
          this.formularioSubirArchivo.component = 'HomologarInputFile';
          this.$nextTick(async () => {
            this.$refs.homologarInputFile.cargarDatosTabla({
              file: this.$refs.filePortafolio.files[0],
              component: component,
            })
          })
        }
      } else if (component == 'homologar') {
        if (this.$refs.fieldFile.files.length > 0) {
          const { comercial, generico, cums, nombre, nit, kamEjecutivo, incluirCotizacion } = this.formularioHomologador;
          // validar que el nit, nombre cotizacion y kamEjecutivo contengan datos
          if (incluirCotizacion && (nombre == '' || nit == '' || kamEjecutivo == '')) {
            this.$refs.fieldFile.value = null;
            this.snackbar = true;
            this.text = 'Los campos: Nombre cotización, nit, KAM/Ejecutivo son obligatorios';
            this.color = 'error'
            return;
          }

          if (cums || (comercial || generico)) {
            this.formularioSubirArchivo.component = 'HomologarInputFile';
            this.$nextTick(async () => {
              this.$refs.homologarInputFile.cargarDatosTabla({
                file: this.$refs.fieldFile.files[0],
                component: component,
              })
            })
          } else {
            this.$refs.fieldFile.value = null;
            this.snackbar = true;
            this.text = 'Seleccione el filtro de homologacion: nombre genérico, nombre comercial o cums';
            this.color = 'error'
          }
        }
      }

    },
    // funcion encargada de recibir la emision del componente hijo y redireccionar al submit correcto
    beforeSubmitForms: function (props) {
      if (props.component == 'portafolio') {
        this.uploadPortafolio(props.columnasFiltradas)
      } else if (props.component == 'homologar') {
        this.filesChange(props.columnasFiltradas)
      }
    },
    // funcion encargada de recibir las columnasFiltradas del componente homogarInputFile y enviarlas al backend
    uploadPortafolio: function (columnasFiltradas) {
      if (this.$refs.filePortafolio.files.length > 0) {

        // Se cambia el estado del formulario
        this.formularioPortafolio.status = STATUS_SAVING;
        // se reinicia los errores
        this.formularioPortafolio.errors = [];

        // obtenemos el primer archivo del input 
        const file = this.$refs.filePortafolio.files[0];

        // se reinicia los productos de la tabla
        this.productosHomologados = [];
        // se crea un formulario 
        const formulario = new FormData();
        // se adiciona el archivo seleccionado por el usuario
        formulario.append("file", file);
        formulario.append("usuarioCreador", this.auth.username);
        formulario.append("columnasFiltradas", JSON.stringify(columnasFiltradas));
        formulario.append("tipoPortafolio", this.formularioHomologador.tipoPortafolio + "");
        this.submitPortafolio(formulario);
      }
    },
    submitPortafolio(formulario) {
      this.formularioPortafolio.msg = [];
      this.$http
        .post("msa-tools/api/v1/portafolio", formulario, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((result) => {
          this.formularioPortafolio.status = STATUS_INITIAL;
          this.getVersionPortafolio();
          this.snackbar = true;
          this.text = result.data.msg + ' Registros afectados: ' + result.data.registrosInsertados;
          this.color = 'success'
        }).catch((error) => {
          console.error(error);
          this.getVersionPortafolio();
          this.formularioPortafolio.status = STATUS_INITIAL;
          this.snackbar = true;
          this.text = 'Error al cargar los productos';
          this.color = 'error'
        });
    },
    filesChange: function (columnasFiltradas) {
      // se oculta la tabla
      this.ocultarTabla = true;
      // se reinicia los errores 
      this.formularioHomologador.errors = [];
      if (this.$refs.fieldFile.files.length > 0) {
        // se obtiene los valores
        const { laboratorio, comercial, generico, cums, nombre, nit } = this.formularioHomologador;
        this.formularioPortafolio.status = STATUS_SAVING;
        // obtenemos el primer archivo del input 
        const file = this.$refs.fieldFile.files[0];
        // se reinicia los productos de la tabla
        this.productosHomologados = [];
        // se crea un formulario 
        const formulario = new FormData();
        // se adiciona el archivo seleccionado por el usuario
        formulario.append("file", file);
        // se adiciona opciones de validacion
        formulario.append("generico", generico);
        formulario.append("laboratorio", laboratorio);
        formulario.append("comercial", comercial);
        formulario.append("nit", nit);
        formulario.append("usuarioCreador", this.auth.username);
        formulario.append("cums", cums);
        formulario.append("nombre", nombre);
        formulario.append("columnasFiltradas", JSON.stringify(columnasFiltradas));
        formulario.append("tipoPortafolio", this.formularioHomologador.tipoPortafolio);
        formulario.append("incluirCotizacion", this.formularioHomologador.incluirCotizacion);
        formulario.append("nombreCliente", this.formularioHomologador.nombreCliente);
        formulario.append("direccionCliente", this.formularioHomologador.direccionCliente);
        formulario.append("ciudadCliente", this.formularioHomologador.ciudadCliente);
        formulario.append("telefonoCliente", this.formularioHomologador.telefonoCliente);
        formulario.append("nombreEjecutivo", this.formularioHomologador.nombreEjecutivo);
        formulario.append("porcentajeDescuento", this.formularioHomologador.porcentajeDescuento);
        formulario.append("diasVigencia", this.formularioHomologador.diasVigencia);
        formulario.append("observacion", this.formularioHomologador.observacion);
        formulario.append("listaPrecio", ((this.formularioHomologador.listaPrecio) ? this.formularioHomologador.listaPrecio.codTipl : ""));
        this.submit(formulario);
      }
    },
    submit(formulario) {
      this.totalHomologado = 0;
      //se reinicia los errores del formulario
      this.formularioHomologador.errors = [];
      this.homologacionReady = false;
      this.totalTime = 0;
      this.time = 0;
      this.interval = setInterval(this.incrementTime, 1000);
      this.currentStatus = STATUS_SAVING;
      this.$http
        .post("msa-tools/homologar", formulario, { headers: { 'Accept': 'application/json' } })
        .then((result) => {
          // se visualiza la tabla
          this.ocultarTabla = false;
          this.homologacionReady = true;
          this.exportAvailable = true;
          this.resetUploadFile();
          this.currentStatus = STATUS_INITIAL;
          clearInterval(this.interval);
          this.$router.push(`historico-homologacion/ver/${result.data.id}`)

        })
        .catch((error) => {
          console.error(error?.response?.data);
          this.text = 'Error al cargar el archivo';
          this.color = 'error'
          // se consulta si el backend responde algun error 
          if (error?.response?.data) {
            this.text = error?.response?.data?.mensaje;
          }
          this.resetUploadFile();
          this.totalTime = this.time;
          clearInterval(this.interval);
          this.currentStatus = STATUS_INITIAL;
          this.snackbar = true;

        });
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    // obtiene el nombre del campo que se esta homologando
    nombreEncabezadoHomogacion(parametro = '') {
      const retorno = this.productosHomologados.find(element => element.id == this.itemPortafolioId);
      if (retorno) return retorno[parametro];
      return '';
    },
    // Obtiene el nombre de la lista de cotizacion
    nombreListaCotizacion(item) {
      return `(${item.codDecl})-${item.nomDcl}-${item.codClie}`;
    },
    buscarNit: debounce(function (e) {
      this.formularioHomologador.nit = e
      if (e != '') {
        this.getClientes();
      }
    }, 500)
  },
  watch: {
    deep: true,
  },
  mounted() {
    this.resetUploadFile();
  },
  components: {
    HomologarManual,
    HomologarInputFile,
    Dummy: {
      template: "<p>&nbsp;</p>",
      data: function () { return {}; }
    },
  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped lang="scss">
.boton-tools {
  padding: 0px 10px 0px 10px !important;

}

.icono-tools {
  align-items: baseline;
  font-size: 56px !important;
  margin-top: 0px !important;
}

.theme--light.v-label {
  color: black !important;
}

.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  height: 100px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 18px 0;
}

.result-icon {
  padding-right: 7px;
}

.toolbar {
  height: 60px !important;
}

.ui.dropdown .menu {
  position: static !important;
}

.help-text {
  font-style: italic;
}

.texto-negro {
  color: black !important;
  position: absolute;
}

.fila-seleccionada {
  background-color: #d2f3d5 !important;
}

.ocultar-elemento {
  display: none;
}

.boton-descarga {
  height: 22px !important;
}

.padding-filtro {
  padding: 0px;
}

::v-deep .tabla-tools div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-tools div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tabla-tools div table tbody tr td {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools div table tbody tr td span div {
  font-size: 0.7rem !important;
}

::v-deep .tabla-tools .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0px 1px;
}


::v-deep .seleccionar-multiple .v-select__selection--comma {
  max-width: 98% !important;
}

.modificado-tabla {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-application .texto-cabecera p {
  margin-bottom: 0px !important;
}

.hijo {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}
</style>